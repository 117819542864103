<template>
  <v-theme-provider light id="factdoc">
    <v-card
      v-if="editedItem.id > 0 || editedItem.id == -1"
      elevation="4"
      :key="kdoc"
    >
      <v-toolbar dense floating class="mt-n2 ml-n2" color="#73DDFF">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="classer"
              v-if="
                solder &&
                (editedItem.statut_id == '14' ||
                  editedItem.statut_id == '132') &&
                editedItem.ttc_paye < editedItem.mont_ttc
              "
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-cancel </v-icon>
              Classer
            </v-btn>
          </template>
          <span>Archiver</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="OpenPayeForm"
              v-if="
                editedItem.statut_id == '14' &&
                (fact_type == 2 || fact_type == 3)
              "
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-list </v-icon>
              Encaissements
            </v-btn>
          </template>
          <span>Afficher Encaissements effectués </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="OpenDpForm"
              v-if="
                editedItem.statut_id == '14' &&
                (fact_type == 1 || fact_type == 4) &&
                ($store.state.auth.includes('02083') || $store.state.isadmin) &&
                editedItem.dp == '0'
              "
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-plus </v-icon>
              Demande Paiement
            </v-btn>
          </template>
          <span>Nouvelle Demande Paiement </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="OpenExpForm"
              v-if="
                editedItem.statut_id == '14' &&
                fact_type == 3 &&
                (facturer || $store.state.isadmin) &&
                editedItem.msq_origin == '1'
              "
              v-bind="attrs"
              v-on="on"
            >
              Expeditions
            </v-btn>
          </template>
          <span>Expeditions lettrées </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="OpenFactForm"
              v-if="editedItem.statut_id == '14' && fact_type == 4"
              v-bind="attrs"
              v-on="on"
            >
              Factures
            </v-btn>
          </template>
          <span>Factures lettrées </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="valider_fact"
              v-if="
                valider &&
                editedItem.statut_id == '13' &&
                fact_detail.length > 0 &&
                !edit
              "
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-check-bold </v-icon>
              Valider
            </v-btn>
          </template>
          <span>Valider</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="deleteFact"
              v-if="
                editer &&
                editedItem.statut_id == '13' &&
                fact_detail.length == 0 &&
                !edit
              "
              :disabled="editedItem.nbdocs > 0 || !editedItem.last_fact"
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-delete </v-icon>Supprimer</v-btn
            >
          </template>
          <span>Supprimer le document</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="modifier"
              v-if="editer && editedItem.statut_id == '13' && !edit"
              v-bind="attrs"
              v-on="on"
              ><v-icon left> mdi-pencil </v-icon>
              Modifier
            </v-btn>
          </template>
          <span>Editer pour modification</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="cancel"
              v-if="editer && editedItem.statut_id == '13' && edit"
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-close </v-icon> Annuler</v-btn
            >
          </template>
          <span>Annuler les Modifications</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="save"
              v-if="editer && editedItem.statut_id == '13' && edit"
              v-bind="attrs"
              v-on="on"
              :disabled="save_disable"
              ><v-icon left> mdi-content-save-outline </v-icon>
              Enregistrer
            </v-btn>
          </template>
          <span>Enregistrer les modifications</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="printfact"
              v-if="
                editedItem.statut_id != '13' &&
                (fact_type == '3' || fact_type == '4')
              "
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-printer </v-icon></v-btn
            >
          </template>
          <span>Imprimer {{ title }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="printbl"
              v-if="editedItem.statut_id != '13'"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-printer </v-icon>
              {{
                fact_type == 3
                  ? "BL/SF"
                  : fact_type == 1
                  ? "BR/SF"
                  : "Bon de retour"
              }}
            </v-btn>
          </template>
          <span
            >Imprimer les
            {{
              fact_type == 3
                ? "Bons de livraisons/Services Fait"
                : fact_type == 1
                ? "Bons de receptions/Services Fait"
                : "Bons de retours"
            }}
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="
                editedItem.id > 0 &&
                !edit &&
                ($store.state.auth.includes('01061') || $store.state.isadmin)
              "
              @click="OpenDocList"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-paperclip </v-icon>
            </v-btn>
          </template>
          <span>Documents Attachés</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="unlock"
              v-if="
                ($store.state.auth.includes('01068') || $store.state.isadmin) &&
                editedItem.ttc_paye == 0 &&
                ((editedItem.factmvms.length == 0 &&
                  editedItem.msq_origin == '1') ||
                  editedItem.msq_origin == '0') &&
                editedItem.statut_id == '14' &&
                editedItem.locked == false &&
                (monobl || (!monobl && editedItem.last_fact)) &&
                !edit
              "
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-lock-open-variant </v-icon>
            </v-btn>
          </template>
          <span>Déverrouiller </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="
                editedItem.id > 0 &&
                ($store.state.auth.includes('01060') || $store.state.isadmin)
              "
              @click.stop="drawer = !drawer"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-clipboard-text-clock-outline </v-icon>
            </v-btn>
          </template>
          <span>Historique Document</span>
        </v-tooltip>
      </v-toolbar>

      <v-progress-linear
        v-if="progress || data_loading"
        indeterminate
        height="10"
        color="light-green darken-4"
        striped
      ></v-progress-linear>
      <v-card class="px-4 scroll" max-height="850">
        <v-card-text>
          <v-row>
            <v-col cols="12" sd="7" md="7" align-self="start">
              <img :src="logo" alt="Company Logo" />
            </v-col>
            <v-col cols="12" sd="5" md="5">
              <v-alert
                v-model="alert"
                :type="type_alert"
                :dismissible="dissm"
                >{{ message }}</v-alert
              >
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-form ref="form" v-model="valid" lazy-validation class="mt-6">
            <v-row>
              <v-col cols="12" sd="6" md="6" lg="6">
                <v-row>
                  <v-col cols="12" sd="11" md="11">
                    <v-row>
                      <v-col cols="12" sm="6" md="10" v-if="edit">
                        <cursorselect
                          :Qsearch="tierscursor"
                          :Qresp="'tiers_cursor'"
                          :value="editedItem.tier_id"
                          :text_fields="[
                            'code_client',
                            'code_fournisseur',
                            'nom',
                          ]"
                          :variableadd="variableadd"
                          :item_text="'tier_name'"
                          @change="tierChange"
                          :label="
                            fact_type == 3 || fact_type == 4
                              ? 'Client'
                              : 'Fournisseur'
                          "
                          :readonly="
                            !modify ||
                            editedItem.factdetails.length > 0 ||
                            !edit
                          "
                          :key="cs"
                        >
                        </cursorselect>
                      </v-col>
                      <!-- 
                      <v-col cols="12" sm="6" md="10" v-if="edit && !all">
                        <v-autocomplete
                          :items="tiers_list"
                          v-model="editedItem.tier_id"
                          :item-value="'tier_id'"
                          :filter="customFilter"
                          :rules="[
                            (v) =>
                              !!v ||
                              (fact_type == 3 || fact_type == 4
                                ? 'Client'
                                : 'Fournisseur') + ' obligatoire',
                          ]"
                          :label="
                            fact_type == 3 || fact_type == 4
                              ? 'Client'
                              : 'Fournisseur'
                          "
                          @change="tierChange"
                          :readonly="
                            !modify ||
                            editedItem.factdetails.length > 0 ||
                            !edit
                          "
                        >
                          <template slot="selection" slot-scope="data">                          
                            {{
                              (fact_type == 3 || fact_type == 4
                                ? data.item.code_client
                                : data.item.code_fournisseur) +
                              "-" +
                              (data.item.tier_name
                                ? data.item.tier_name
                                : data.item.nom)
                            }}
                          </template>
                          <template slot="item" slot-scope="data">                           
                            {{
                              (fact_type == 3 || fact_type == 4
                                ? data.item.code_client
                                : data.item.code_fournisseur) +
                              "-" +
                              (data.item.tier_name
                                ? data.item.tier_name
                                : data.item.nom)
                            }}
                          </template>
                        </v-autocomplete>
                      </v-col> -->
                      <!-- <v-col
                        cols="12"
                        sm="6"
                        md="2"
                        v-if="edit && fact_type > 1"
                      >
                        <v-checkbox v-model="all" label="Tous"></v-checkbox>
                      </v-col> -->
                      <v-col
                        cols="12"
                        sd="11"
                        md="11"
                        class="text-left"
                        v-if="
                          edit &&
                          fact_type == 3 &&
                          paye_bl &&
                          auxiliaires.length > 0
                        "
                      >
                        <v-autocomplete
                          dense
                          :items="auxiliaires"
                          item-value="aux_id"
                          v-model="editedItem.aux_id"
                          label="Auxiliaire"
                          :readonly="!edit"
                          @change="aux_change"
                          @input="cmpt++"
                          clearable
                        >
                          <template v-slot:selection="{ item }">
                            <!-- HTML that describe how select should render selected items -->
                            {{ item.aux_code + "-" + item.aux_name }}
                          </template>
                          <template v-slot:item="{ item }">
                            <!-- HTML that describe how select should render selected items -->
                            {{ item.aux_code + "-" + item.aux_name }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" sd="2" md="4" class="text-left" v-if="!edit">
                    {{ tier_title }}
                  </v-col>

                  <v-col cols="12" sd="2" md="8" v-if="!edit">
                    <strong
                      ><h3>{{ editedItem.tier_name }}</h3></strong
                    >
                  </v-col>

                  <v-col cols="12" sd="2" md="4"> Code </v-col>
                  <v-col cols="12" sd="2" md="8">
                    <a @click="gotier" v-if="consult_tier">
                      {{ editedItem.code_tier }}
                    </a>
                    <div v-else>
                      {{ editedItem.code_tier }}
                    </div>
                  </v-col>

                  <v-col cols="12" sd="2" md="4"> Adresse</v-col>
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.adresse }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="editedItem.country_id == 13"
                  >
                    R.C. N°</v-col
                  >
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.RC }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="editedItem.country_id == 13"
                    >N° Article</v-col
                  >
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.ART }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="editedItem.country_id == 13"
                    >Id Fiscal</v-col
                  >
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.NIF }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="editedItem.country_id == 13"
                    >N° Compte</v-col
                  >
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.cmpt }}
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sd="2" md="6" lg="6">
                <v-row>
                  <v-col cols="12" sd="12" md="12">
                    <h2>{{ formTitle }}</h2>
                  </v-col>

                  <v-col cols="12" sd="12" md="12" lg="12">
                    <strong> Date : {{ datefr(editedItem.fact_date) }}</strong>
                  </v-col>
                  <v-col cols="12" sd="12" md="12" lg="12">
                    <strong v-if="!edit">
                      Ref. : {{ editedItem.fact_ref }}</strong
                    >
                  </v-col>
                  <v-col cols="12" sd="12" md="12" lg="12" v-if="!edit">
                    <v-row
                      v-for="mvm in editedItem.factmvms"
                      :key="mvm.id"
                      dense
                      :class="
                        editedItem.msq_origin == 1 ? 'red--text text-h6' : ''
                      "
                    >
                      <v-col cols="12" sm="12" md="12">
                        {{ mvm.label }}
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-row
                          v-for="ret in mvm.retours.filter(
                            (elm) =>
                              elm.decision_ret == 3 && elm.statut_id == 11
                          )"
                          :key="ret.id"
                          dense
                        >
                          <v-col cols="12" sm="12" md="12">
                            {{ ret.label }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <strong>
                          {{
                            " Commandes Refs:" +
                            (editedItem.cmds_ref
                              ? editedItem.cmds_ref
                              : editedItem.cmds_no
                              ? editedItem.cmds_no
                              : "")
                          }}
                        </strong>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sd="12" md="12" lg="12">
                    <v-row dense v-if="edit">
                      <v-col cols="12" sd="6" md="6" class="text-left">
                        <v-text-field
                          autocomplete="off"
                          dense
                          class="inputPrice"
                          v-model="editedItem.fact_ref"
                          :readonly="!edit"
                          label="Reférence facture"
                          :rules="[
                            (v) =>
                              !!v ||
                              fact_type > 2 ||
                              'Reférence facture obligatoire',
                          ]"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sd="6" md="6">
                        <datepicker
                          label="
                          Date Facture
                        "
                          v-model="editedItem.fact_date"
                          :rules="[(v) => !!v || 'Date Facture obligatoire']"
                          :date_min="
                            fact_type == 1 ||
                            fact_type == 2 ||
                            !editedItem.nofact
                              ? last_facture.last_fact_date
                              : editedItem.min_date
                          "
                          :date_max="
                            fact_type == 1 ||
                            fact_type == 2 ||
                            !editedItem.nofact
                              ? null
                              : editedItem.max_date
                          "
                          :edit="edit"
                        ></datepicker>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        v-if="fact_type == 3 && facturer"
                      >
                        <v-checkbox
                          v-model="editedItem.msq_origin"
                          label="Expedition Ultérieure"
                          class="ma-2"
                          :disabled="mvms_select.length > 0"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        v-if="!editedItem.msq_origin"
                      >
                        <v-autocomplete
                          v-model="mvms_select"
                          :items="mvms"
                          :item-text="
                            (item) =>
                              (item.nomvm ? item.nomvm : item.nobon) +
                              ' du ' +
                              datefr(item.opr_date) +
                              ' Montant: ' +
                              numberWithSpace(
                                item.mont_ht ? item.mont_ht.toFixed(2) : '0.00'
                              )
                          "
                          item-value="id"
                          :label="
                            fact_type == 1
                              ? 'Bons de reception/Services Fait'
                              : fact_type == 3
                              ? 'Bons d\'expedition/Services Fait'
                              : 'Bons de retour'
                          "
                          :readonly="!edit"
                          multiple
                          small-chips
                          deletable-chips
                          clearable
                          dense
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3" md="3">
                        <v-checkbox
                          v-model="editedItem.exo_tva"
                          label="Exonoré de la TVA "
                          :readonly="!edit"
                          class="pa-1"
                          dense
                          @change="cmpt++"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="3" md="4">
                        <v-select
                          dense
                          v-model="editedItem.modepaiement"
                          :items="modes_list"
                          item-text="mode"
                          item-value="id"
                          label="Mode Paiement"
                          :readonly="
                            !edit ||
                            !editer ||
                            (editedItem.paye_statut == '1' &&
                              editedItem.statut_id > '13')
                          "
                          :rules="[
                            (v) => !!v || 'Mode de paiement obligatoire',
                          ]"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sd="7" md="7" class="text-left">
                        <v-row>
                          <v-col cols="12" sd="6" md="9">
                            <datepicker
                              label="
                          Délai de Paiement
                        "
                              v-model="duedate"
                              :edit="edit"
                              @change="nbjour"
                              :key="kdd"
                              :date_min="editedItem.fact_date"
                              :date_max="null"
                            ></datepicker>
                          </v-col>
                          <v-col cols="12" sd="3" md="3">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              dense
                              class="inputPrice"
                              v-model.number="editedItem.delai_paye"
                              :label="'Jours'"
                              :readonly="!editer || !edit"
                              @input="addDays"
                              hide-spin-buttons
                              @focus="$event.target.select()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sd="4" md="4" v-if="fact_type == 3">
                        <v-text-field
                          autocomplete="off"
                          type="number"
                          dense
                          class="inputPrice"
                          v-model.number="editedItem.garantie"
                          :label="'Retenue de Garantie (%)'"
                          :readonly="!editer || !edit"
                          hide-spin-buttons
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        v-if="fact_type == 3 && comptes.length > 1"
                      >
                        <v-select
                          :items="comptes"
                          v-model="editedItem.compte_id"
                          :item-text="'agence_compte'"
                          :item-value="'id'"
                          :rules="[(v) => !!v || 'Compte obligatoire']"
                          :label="'Compte'"
                          :readonly="!edit"
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sd="12" md="12" class="text-left">
                        <v-text-field
                          dense
                          v-model="editedItem.note"
                          label="Note"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sd="12" md="12" class="text-left">
                        <v-text-field
                          dense
                          v-model="editedItem.comment"
                          label="Commentaire"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-col cols="12" sd="12" md="12" v-if="!edit">
              <listitems
                :list="fact_detail"
                :title="'Items'"
                :headers="products_headers"
                :filename="'Items'"
                :sheetname="'Items'"
                :master="true"
                :showedit="false"
                :key="klist"
                :add="
                  editer &&
                  facturer &&
                  fact_type != 1 &&
                  fact.statut_id == 13 &&
                  fact.id > 0
                "
                :Update="true"
                :del="
                  editer &&
                  facturer &&
                  fact_type != 1 &&
                  fact.statut_id == 13 &&
                  fact.id > 0
                "
                :del_disable="factdetail.mvm_id > 0"
                :elevation="0"
                @open="OpenDetailForm"
                @rowselect="DetailSelect"
                @delete="deleteItem"
                :ipg="ipg"
                @options="send_option"
              >
              </listitems>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="!edit" class="pa-3">
            <v-col cols="12" sd="9" md="9" lg="9">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="fact_type != 6"
                >
                  DELAI DE PAIEMENT

                  {{
                    editedItem.delai_paye
                      ? editedItem.delai_paye +
                        (parseInt(editedItem.delai_paye) != "NAN"
                          ? " jours"
                          : "")
                      : ""
                  }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left r">
                  MODE DE PAIEMENT :
                  {{ mode }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  NB : {{ editedItem.note }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Comment : {{ editedItem.comment }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Etablit Par : {{ editedItem.contact }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sd="3" md="3" lg="3">
              <v-row no-gutters>
                <v-col cols="12" sd="6" md="4">
                  {{ editedItem.country_id == 13 ? "Total H.T" : "Total" }}
                  :
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold nowrap text-right pa-1"
                >
                  <h3>{{ numberWithSpace(subTotal) }}</h3>
                </v-col>
              </v-row>
              <!-- <v-row v-if="existe_remise" no-gutters>
              <v-col cols="12" sd="6" md="4"> Remise</v-col>
              <v-col cols="12" sd="6" md="8" class="text-bold text-right pa-1">
                {{ numberWithSpace(remise) }}
              </v-col>
            </v-row> -->
              <v-row v-for="(tax, taux) in taxTotal" :key="taux" no-gutters>
                <v-col cols="12" sd="6" md="4"> T.V.A {{ tax.taux }} % </v-col>
                <v-col cols="12" sd="6" md="8" class="text-right pa-1">
                  {{
                    numberWithSpace(
                      decimalDigits(
                        parseFloat(((tax.ht * tax.taux) / 100).toFixed(2))
                      )
                    )
                  }}
                </v-col>
              </v-row>
              <v-row v-if="taxTotal.length > 1" no-gutters>
                <v-col cols="12" sd="6" md="4"> Total T.V.A </v-col>
                <v-col cols="12" sd="6" md="8" class="text-right pa-1">
                  {{ numberWithSpace(decimalDigits(sumtaxTotal())) }}
                </v-col>
              </v-row>
              <v-row v-if="timbre > 0" no-gutters>
                <v-col cols="12" sd="6" md="4"> Droit de timbre (1%)</v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold text-right pa-1"
                >
                  {{ numberWithSpace(timbre) }}
                </v-col>
              </v-row>
              <v-row v-if="subTotal != ttc" no-gutters>
                <v-col cols="12" sd="6" md="4"> Total T.T.C</v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold text-right pa-1"
                >
                  <h3>{{ numberWithSpace(ttc) }}</h3>
                </v-col>
              </v-row>
              <v-row v-if="garantie > 0" no-gutters>
                <v-col cols="12" sd="6" md="4">
                  Retenue de Garantie {{ editedItem.garantie }}%</v-col
                >
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold text-right pa-1"
                >
                  -{{ numberWithSpace(garantie) }}
                </v-col>
              </v-row>
              <v-row v-if="avc_cmds > 0" no-gutters>
                <v-col cols="12" sd="6" md="4">
                  Rembourssement Avance Forfaitaire</v-col
                >
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold text-right pa-1"
                >
                  -{{ numberWithSpace(avc_cmds) }}
                </v-col>
              </v-row>
              <v-row v-if="grandTotal != ttc" no-gutters>
                <v-col cols="12" sd="6" md="4"> Montant Net</v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold text-right pa-1"
                >
                  {{ numberWithSpace(grandTotal) }}
                </v-col>
              </v-row>
              <v-row
                v-if="mont_exe_taxs > 0 && editedItem.aff_exe == 1"
                no-gutters
              >
                <v-col cols="12" sd="6" md="4">TVA 19% (Exonoré)</v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold text-right pa-1"
                >
                  {{ numberWithSpace(mont_exe_taxs) }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <template>
            <confirmdialog
              ref="confirm"
              :is_motif="is_motif"
              @motif="get_motif"
            />
          </template>

          <v-snackbar
            v-model="snackbar"
            :timeout="snackbar_timeout"
            top
            :color="snackbar_color"
          >
            {{ snackbar_text }}
          </v-snackbar>

          <factpayelist
            :fact="editedItem"
            :showpaye="showpaye"
            :paye_list="paye_list"
            @add="add_paye"
            :add="
              editedItem.paye_statut == '0' &&
              editedItem.statut_id == '14' &&
              ((fact_type == 1 && $store.state.auth.includes('02016')) ||
                (fact_type == 2 && $store.state.auth.includes('02017')) ||
                (fact_type == 3 && $store.state.auth.includes('02017')) ||
                (fact_type == 4 && $store.state.auth.includes('02016')) ||
                $store.state.isadmin)
            "
            :key="fp"
            @close="ClosePayeForm"
          >
          </factpayelist>
          <factmvmlist
            :fact="editedItem"
            :showexp="showexp"
            :exp_list="editedItem.factmvms"
            @add="add_exp"
            @del="del_exp"
            :add="
              !editedItem.locked &&
              editedItem.msq_origin == '1' &&
              editedItem.statut_id == '14' &&
              fact_type == 3 &&
              (facturer || $store.state.isadmin) &&
              editedItem.mont_ttc > editedItem.mont_exp
            "
            :del="!editedItem.locked"
            :key="fe"
            @close="CloseExpForm"
          >
          </factmvmlist>
          <factfactlist
            :fact="editedItem"
            :showfact="showfact"
            :fact_list="editedItem.factures"
            @add="add_fact"
            @del="del_fact"
            :add="
              !editedItem.locked &&
              editedItem.statut_id == '14' &&
              fact_type == 4 &&
              editedItem.mont_ttc > editedItem.ttc_let
            "
            :del="!editedItem.locked"
            :key="ff"
            @close="CloseFactForm"
          >
          </factfactlist>
          <factdetailform
            :item="factdetail"
            :items="fact_detail"
            :key="dkey"
            :showForm="!isDetailClosed"
            @close="closeDetailForm"
          >
          </factdetailform>
          <filelist
            :item="editedItem"
            :showform="!isDocListClose"
            :key="fdocl"
            @close="isDocListClose = true"
            :editer="editer"
            :doc_type="4"
            :auth="auth"
            :width="800"
          >
          </filelist>
          <timeline
            :document_type="4"
            :document_id="parseInt(editedItem.id)"
            v-model="drawer"
            :operations="operations"
            :doc_type="'Facture'"
            :doc_code="editedItem.nofact"
          >
          </timeline>
          <factmvmlett
            :facture="fact"
            :key="fkey"
            :showForm="!isMvmLettClosed"
            @close="closeMvmLettForm"
          >
          </factmvmlett>
          <factfactlett
            :facture="fact"
            :key="ffkey"
            :showForm="!isFactLettClosed"
            @close="closeFactLettForm"
          >
          </factfactlett>
        </v-card-text>
      </v-card>
    </v-card>
  </v-theme-provider>
</template>
<script>
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
//const cursorselect = () => import("./CursorSelect.vue");

import DELETE_FACT from "../graphql/Facture/DELETE_FACT.gql";
import UPDATE_FACT from "../graphql/Facture/UPDATE_FACT.gql";
import UPSERT_FACT from "../graphql/Facture/UPSERT_FACT.gql";
import DELETE_FACT_DETAIL from "../graphql/Facture/DELETE_FACT_DETAIL.gql";
import DELETE_FACT_MVM from "../graphql/Facture/DELETE_FACT_MVM.gql";
import INSERT_PAYE_DETAIL from "../graphql/Paiement/INSERT_PAYE_DETAIL.gql";
import ALLPAYE from "../graphql/Facture/ALLPAYE.gql";
import UPDATE_MODIF from "../graphql/UPDATE_MODIF.gql";
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";
import LAST_FACTURE from "../graphql/Facture/LAST_FACTURE.gql";

//import { facture } from "print/facture.js";
import { doc_template } from "print/template.js";
import { datefr } from "@/utils/functions.js";
import { livraison } from "print/livraison.js";
import { reception } from "print/reception.js";
import { myLogo, myName, myIF, myRC, myART, monobl, myBL } from "print/data.js";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    factpayelist: () => import("./FactPayeList.vue"),
    factmvmlist: () => import("./FactMvmList.vue"),
    factfactlist: () => import("./FactFactList.vue"),
    filelist: () => import("./FileList.vue"),
    listitems: () => import("./ListItems.vue"),
    factdetailform: () => import("./FactDetailForm.vue"),
    datepicker: () => import("./DatePicker.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
    timeline: () => import("./Timeline.vue"),
    factmvmlett: () => import("../components/FactMvmLettrage.vue"),
    factfactlett: () => import("../components/FactFactLettrage.vue"),
  },
  props: {
    fact: Object,
    fact_type: Number,
    editer: Boolean,
    valider: Boolean,
    solder: Boolean,
    facturer: Boolean,
    data_loading: Boolean,
    modes: Array,
    comptes: Array,
    mvms_list: Array,
    edition: { type: Boolean, default: false },
    ipg: { type: Number, default: 10 },
    last_facture: Object,
    exercicesperiodes: Array,
  },
  data() {
    return {
      isMvmLettClosed: true,
      isFactLettClosed: true,
      fkey: 200,
      ffkey: 3000,
      save_disable: false,
      drawer: null,
      alltier: false,
      modify: false,
      paye_bl: false,
      progress: false,
      loading: false,
      duedate: null,
      valid: true,
      is_motif: false,
      date_min: null,
      cs: 2000,
      numberRule: (v) => {
        if (!v || (!isNaN(parseFloat(v)) && v >= 0 && v <= 100)) return true;
        return "Nombre doit être entre 0 and 100";
      },
      act_save: true,
      isDetailClosed: true,
      isDocListClose: true,
      snackbar: false,
      snackbar_timeout: 5000,
      snackbar_text: "",
      snackbar_color: "primary",
      factdetail: {},
      message: "",
      alert: false,
      type_alert: "error",
      dissm: true,
      client: "Client information",
      mc: 200,
      edit: false,
      dkey: 100,
      kdd: 300,
      pkey: 400,
      fdocl: 1000,
      showpaye: false,
      showexp: false,
      showfact: false,
      changed: false,
      mvms: [],
      auxiliaires: [],
      products_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          slot: "href",
          align: "left",
          width: "auto",
        },
        {
          text: "Designation",
          value: "label",
          slot: "href99",
          column: "article_id",
          selected: true,
          align: "left",
          width: "auto",
        },
        {
          text: "PU HT",
          value: "pu",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "Remise(%)",
          value: "remise",
          slot: "cur",
          hiden: true,
          align: "end",
          selected: true,
        },
        {
          text: "Quantité",
          value: "qte",
          slot: "cur",
          align: "end",
          selected: true,
          width: "auto",
        },
        {
          text: "Montant HT",
          value: "ht",
          slot: "cur",
          align: "end",
          selected: true,
          width: "auto",
        },
      ],

      editedItem: {},
      mvms_select: [],
      modes_list: [],
      klist: 500,
      kdoc: 700,
      fp: 300,
      fe: 400,
      ff: 600,
      cmpt: 0,
      consult_tier: false,
      defaultItem: {
        id: -1,
        status_name: "Brouillon",
        statut_id: "13",
        factmvms: [],
        factdetails: [],
        docs: [],
        paye_statut: "0",
      },
      products_depots: [],
      cmds: [],
      adresses: [],
      payes_list: [],
    };
  },

  async created() {
    if (
      myBL == "n" &&
      (this.$store.state.auth.includes("05021") || this.$store.state.isadmin)
    )
      this.paye_bl = true;
    if (this.fact)
      if (this.fact.id) {
        this.edit = this.edition;
        if (this.fact.id > 0) {
          this.editedItem = Object.assign({}, this.fact);
          if (this.editedItem.statut_id != "13")
            this.products_headers[2].slot = null;

          this.addDays();
          // let i = this.tiers_list.findIndex(
          //   (elm) => elm.tier_id == this.editedItem.tier_id
          // );
          // this.all = i == -1;
          this.auxiliaires = this.editedItem.auxiliaires;
          this.tierChange();
          if (this.editedItem.aux_id) this.aux_change();
          this.mvms_select = this.editedItem.factmvms.map((elm) => elm.mvm_id);
          if (this.editedItem.statut_id == "15") {
            this.message =
              "Classée pour motif : " +
              (this.editedItem.motif ? this.editedItem.motif : "");
            this.alert = true;
          }
          if (
            this.editedItem.ttc_paye > 0 &&
            this.editedItem.ttc_paye >= this.editedItem.mont_ttc
          ) {
            this.message = "Reglée";
            this.type_alert = "success";
            this.dissm = false;
            this.alert = true;
          }
          if (this.editedItem.locked) {
            this.message =
              this.editedItem.ttc_paye > 0 &&
              this.editedItem.ttc_paye >= this.editedItem.mont_ttc
                ? "Reglée et Comptabilisée "
                : "Comptabilisée ";
            this.type_alert =
              this.editedItem.ttc_paye > 0 &&
              this.editedItem.ttc_paye >= this.editedItem.mont_ttc
                ? "success"
                : "warning";
            this.dissm = false;
            this.alert = true;
          }

          this.modes_list = this.modes.filter(
            (elm) => elm.type == (this.editedItem.country_id == 13 ? 0 : 1)
          );
        } else {
          this.editedItem = Object.assign({}, this.defaultItem);
          if (this.fact_type >= 3)
            this.editedItem.fact_date = this.$store.state.today;
          if (this.fact.tier_id > 0) {
            this.editedItem.tier_id = this.fact.tier_id;
            this.editedItem.country_id = this.fact.country_id;
            this.editedItem.RC = this.fact.RC;
            this.editedItem.NIF = this.fact.NIF;
            this.editedItem.ART = this.fact.ART;
            this.editedItem.code_cmpt = this.fact.code_cmpt;
            this.tierChange();
            this.mvms_select = [];
            this.mvms_select.push(this.fact.mvm_id);
            if (this.fact.modepaiement)
              this.editedItem.modepaiement = this.fact.modepaiement;
            if (this.fact.delai_paye)
              this.editedItem.delai_paye = this.fact.delai_paye;

            // let i = this.tiers_list.findIndex(
            //   (elm) => elm.tier_id == this.editedItem.tier_id
            // );

            // if (i == -1) {
            //   this.all = true;
            //   this.cs++;
            // }
          }
        }

        if (this.comptes) {
          if (this.comptes.length == 1)
            this.editedItem.compte_id = this.comptes[0].id;
          else {
            let i = this.comptes.findIndex((elm) => elm.default == 1);
            if (i >= 0) this.editedItem.compte_id = this.comptes[i].id;
          }
        }
        // if (this.fact.tier_id && this.fact_type == 3) {
        //   let v = {
        //     where: {
        //       AND: [
        //         { column: "STATUT_ID", operator: "GTE", value: 17 },
        //         { column: "TIER_ID", value: this.fact.tier_id },
        //         { column: "CMD_ID", operator: "IS_NOT_NULL" },
        //       ],
        //     },
        //   };
        //   let r = await this.requette(ALLPAYE, v);
        //   if (r) {
        //     this.payes_list = r.allpaye.filter(
        //       (elm) => elm.taux_avance > 0 && elm.reste > 0
        //     );
        //   }
        // }
      }

    this.modify = this.editer && this.editedItem.statut_id == "13";

    this.products_headers[4].hiden = !this.existe_remise;
    this.consult_tier =
      ((this.fact_type == 3 || this.fact_type == 4) &&
        this.$store.state.auth.includes("01030")) ||
      ((this.fact_type == 1 || this.fact_type == 2) &&
        this.$store.state.auth.includes("01029")) ||
      this.$store.state.isadmin;
  },

  methods: {
    closeMvmLettForm() {
      this.isMvmLettClosed = true;
    },
    closeFactLettForm() {
      this.isFactLettClosed = true;
    },
    aux_change() {
      let i = this.auxiliaires.findIndex(
        (elm) => elm.id == this.editedItem.aux_id
      );
      if (i >= 0) {
        this.editedItem.code_tier = this.auxiliaires[i].aux_code;
        this.editedItem.adresse = this.auxiliaires[i].adresse;
        this.editedItem.RC = this.auxiliaires[i].RC;
        this.editedItem.ART = this.auxiliaires[i].ART;
        this.editedItem.NIF = this.auxiliaires[i].NIF;
      }
    },
    send_option(item) {
      this.$emit("options", item);
    },
    customFilter(item, queryText) {
      let textlist = [];
      ["code_client", "code_fournisseur", "tier_name", "matricule"].forEach(
        (element) => {
          const text1 = item[element] ? item[element].toLowerCase() : "";
          textlist.push(text1);
        }
      );

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    async deleteItem(item) {
      if (await this.verif_modif("d")) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer cet element?"
          )
        ) {
          let v = {
            id: item.id,
          };
          await this.maj(DELETE_FACT_DETAIL, v);
          this.fact_detail.splice(item.index, 1);
          this.$store.dispatch("Changed", true);
        } else this.update_modif();
        this.$store.dispatch("Editing", false);
      }
    },
    OpenDocList() {
      this.isDocListClose = false;
      this.fdocl++;
    },
    get_motif(m) {
      this.editedItem.motif = m;
    },
    async maj(query, v, refresh, p = true) {
      let r;
      this.progress = p;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.progress = false;
        });
      return r;
    },
    OpenPayeForm() {
      this.fp++;
      this.showpaye = true;
    },
    OpenExpForm() {
      this.fe++;
      this.showexp = true;
    },
    OpenFactForm() {
      this.ff++;
      this.showfact = true;
    },
    OpenDpForm() {
      this.$router.push({
        name: "dps_fournis",
        params: { fact: this.fact, name: "facturations_f" },
      });
    },

    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },

    numberWithSpace: function (x) {
      return x
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "";
    },

    decimalDigits: function (value) {
      return typeof value == "number" ? value.toFixed(2) : 0;
    },

    sumtaxTotal() {
      // sum data in give key (property)
      return this.taxTotal
        ? this.taxTotal.reduce(
            (a, b) => a + (parseFloat(((b.ht * b.taux) / 100).toFixed(2)) || 0),
            0
          )
        : 0;
    },
    async save() {
      if (this.$refs.form ? this.$refs.form.validate() : true) {
        this.save_disable = true;
        let ok = true;

        if (
          (!this.editedItem.RC ||
            !this.editedItem.NIF ||
            !this.editedItem.ART) &&
          this.editedItem.country_id == "13"
        ) {
          this.message = "identite fiscale du tier incomplete! ";
          this.type_alert = "error";
          this.alert = true;
          console.log("identite fiscale du tier incomplete!");
          ok = false;
        }

        if (
          ok &&
          !this.editedItem.code_cmpt &&
          this.editedItem.country_id == "13" &&
          this.$store.state.options[0].transf_cmpt == 1
        ) {
          this.snackbar_text = "code comptable du tier non defini!";
          this.snackbar_color = "warning";
          this.snackbar = true;
          //ok = false;
        }
        if (ok) {
          let v = {
            fact: [
              {
                id: this.editedItem.id > 0 ? this.editedItem.id : null,
                fact_type: this.fact_type,
                fact_ref: this.editedItem.fact_ref,
                tier_id: this.editedItem.tier_id,
                aux_id: this.editedItem.aux_id,
                fact_date: this.editedItem.fact_date,
                msq_origin: this.editedItem.msq_origin ? 1 : 0,
                comment: this.editedItem.comment,
                statut_id: this.editedItem.statut_id,
                delai_paye: this.editedItem.delai_paye,
                garantie: this.editedItem.garantie,
                compte_id: this.editedItem.compte_id,
                note: this.editedItem.note,
                exo_tva: this.editedItem.exo_tva ? 1 : 0,
                modepaiement: this.editedItem.modepaiement,
                mvm_list: this.mvms_select,
              },
            ],
            facture: 0,
          };

          let r = await this.maj(UPSERT_FACT, v, true);
          if (r) {
            this.editedItem.id = r.UpsertFact;
            this.$store.dispatch("Changed", true);
            this.$emit("change", this.editedItem);
            this.$route.params.mvm = null;
          }
          this.edit = false;
          this.$emit("edition", false);

          this.kdoc++;
        }
        this.$store.dispatch("Editing", false);
        this.save_disable = false;
      }
    },
    async unlock() {
      // verif last facture

      let ok = true;
      if (!monobl) {
        let lf = {};
        let r = await this.requette(LAST_FACTURE);
        if (r) {
          let l = r.getlastfacture.filter(
            (elm) => elm.fact_type == this.fact_type
          );
          if (l.length > 0) {
            lf = l[0];
            if (lf.last_order > this.editedItem.fact_order) {
              this.message = "Not last Erreur déverrouillage impossible ";
              this.type_alert = "error";
              this.alert = true;
              this.$store.dispatch("Editing", false);
              ok = false;
            }
          }
        }
      }
      if (ok)
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir déverrouiller cette facture?"
          )
        ) {
          this.editedItem.statut_id = 13;
          let v = {
            fact: [
              {
                id: this.editedItem.id,
                statut_id: this.editedItem.statut_id,
                mvm_list: this.mvms_select,
              },
            ],
            facture: 0,
            monobl: monobl,
          };

          await this.maj(UPSERT_FACT, v, true);
          this.$emit("refresh");
          this.$emit("change", this.editedItem);
        }
      this.$store.dispatch("Editing", false);
    },
    async classer() {
      if (await this.verif_modif("c")) {
        this.is_motif = true;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir classer cette facture?"
          )
        ) {
          this.editedItem.statut_id = 15;
          let v = {
            fact: {
              id: this.editedItem.id,
              statut_id: this.editedItem.statut_id,
              motif: this.editedItem.motif,
            },
          };

          await this.maj(UPDATE_FACT, v, true);
          this.$emit("change", this.editedItem);
        }
        this.is_motif = false;
      }
      this.$store.dispatch("Editing", false);
    },
    Openlett() {},
    async valider_fact() {
      if (await this.verif_modif("v")) {
        if (this.$refs.form.validate()) {
          let ok = true;
          let lf = {};

          // verif periode facture
          const df = new Date(this.editedItem.fact_date);
          const exercice = df.getYear();
          const month = df.getMonth();

          let j = this.exercicesperiodes.findIndex(
            (elm) =>
              elm.exercice == exercice &&
              elm.periode == month + 1 &&
              elm.cloture == 1
          );
          if (j >= 0) {
            this.message =
              "Periode comptable de cette date facture est cloturée! ";
            this.type_alert = "error";
            this.alert = true;
            this.$store.dispatch("Editing", false);
            ok = false;
          }

          // verif date facture
          if (this.fact_type == 3 || this.fact_type == 4) {
            let r = await this.requette(LAST_FACTURE);
            if (r) {
              let l = r.getlastfacture.filter(
                (elm) => elm.fact_type == this.fact_type
              );
              if (l.length > 0) {
                lf = l[0];
                if (
                  lf.last_fact_date > this.editedItem.fact_date &&
                  !this.editedItem.nofact
                ) {
                  this.message =
                    "Erreur date facture! date minimale est " +
                    this.datefr(lf.last_fact_date, false, false);
                  this.type_alert = "error";
                  this.alert = true;
                  this.$store.dispatch("Editing", false);
                  ok = false;
                }
              }
            }
          }
          //verifier mvms

          if (
            this.editedItem.statut_id == 13 &&
            this.editedItem.modepaiement == "4" &&
            this.ttc > 100000 &&
            ok
          ) {
            this.message =
              "Montant TTC superieur à 100 000.00 DA pour le mode réglement en espéce!";
            this.type_alert = "error";
            this.alert = true;
            this.$store.dispatch("Editing", false);
            ok = false;
          }
          if (ok) {
            for (
              let index = 0;
              index < this.editedItem.factmvms.length;
              index++
            ) {
              const element = this.editedItem.factmvms[index];
              if (
                element.is_fact ||
                ((this.fact_type == 2 || this.fact_type == 4) &&
                  element.decision_ret != 2)
              ) {
                this.message =
                  (this.fact_type == 1
                    ? "Reception"
                    : this.fact_type == 3
                    ? "Expedition"
                    : "Retour") +
                  " N° " +
                  element.nobon +
                  " du " +
                  this.datefr(element.mvm_date, false, false) +
                  " introuvable! validation impossible";
                this.type_alert = "error";
                this.alert = true;
                this.$store.dispatch("Editing", false);
                ok = false;
                break;
              }
              if (this.fact_type == 1 || this.fact_type == 3) {
                let i = element.retours.findIndex(
                  (elm) => elm.decision_ret == null || elm.statut_id == 10
                );
                if (i >= 0) {
                  this.message =
                    (this.fact_type == 1 ? "Reception" : "Expedition") +
                    " N° " +
                    element.nobon +
                    " du " +
                    this.datefr(element.mvm_date, false, false) +
                    " avec le retour" +
                    " N° " +
                    element.retours[i].nobon +
                    " du " +
                    this.datefr(element.retours[i].mvm_date, false, false) +
                    (element.retours[i].statut_id == 10
                      ? " non validé! "
                      : " non traité! ") +
                    " validation impossible";
                  this.type_alert = "error";
                  this.alert = true;
                  this.$store.dispatch("Editing", false);
                  ok = false;
                  break;
                }
              }
            }
          }
          // if (ok && this.editedItem.msq_origin == 1) {
          //   let tot_org = 0;
          //   this.editedItem.factmvms.forEach((element) => {
          //     tot_org = tot_org + element.mont_ht;
          //     element.retours
          //       .filter((elm) => elm.decision_ret == 3)
          //       .forEach((elm) => {
          //         tot_org = tot_org - elm.mont_ht;
          //       });
          //   });
          //   if (Math.floor(this.subTotal) != Math.floor(tot_org)) {
          //     this.message =
          //       "Montant total facture erroné! doit être egale à " +
          //       this.numberWithSpace(Math.floor(tot_org).toFixed(2));
          //     this.type_alert = "error";
          //     this.alert = true;
          //     ok = false;
          //   }
          // }
          if (ok)
            if (
              await this.$refs.confirm.open(
                "Confirmation",
                "Etês-vous sûr de vouloir valider cette facture?"
              )
            ) {
              let st = 14;
              let v = {
                fact: [
                  {
                    id: this.editedItem.id,
                    statut_id: st,
                    mont_avc: this.avc_cmds,
                    mont_grt: this.garantie,
                    mont_net: parseFloat(this.grandTotal),
                    mvm_list: this.mvms_select,
                    //mont_remise: parseFloat(this.remise),
                  },
                ],
                facture: 1,
                monobl: monobl,
              };
              this.$store.dispatch("Editing", false);

              let r = await this.maj(UPSERT_FACT, v, true);
              if (r) {
                //  if (this.fact_type == 3) await this.updatePaye();
                this.editedItem.statut_id = st;
                this.$emit("refresh", this.editedItem);
                this.$emit("change", this.editedItem);
              }
              //}
            } else this.update_modif();
        }
      }
    },

    async updatePaye() {
      let b = [];
      //lettrage de l'avance
      if (this.payes_list.length > 0 && this.avc_cmds > 0) {
        b.push({
          paye_id: this.payes_list[0].id,
          fact_id: this.editedItem.id,
          montant: parseFloat(this.avc_cmds),
          create_uid: this.$store.state.me.id,
          write_uid: this.$store.state.me.id,
        });
      }

      //lettrage auto de la facture
      let v = {
        where: {
          AND: [
            { column: "STATUT_ID", operator: "GTE", value: 17 },
            { column: "TIER_ID", value: this.editedItem.tier_id },
            { column: "TAUX_AVANCE", value: 0 },
            { column: "RESTE", operator: "GT", value: 0 },
          ],
        },
      };
      let p;
      let r = await this.requette(ALLPAYE, v);
      if (r) {
        p = r.allpaye.filter((elm) => elm.taux_avance == 0 && elm.reste > 0);
      }
      let j = p.findIndex(
        (elm) => (!elm.taux_avance || elm.taux_avance == 0) && elm.reste > 0
      );
      if (j >= 0) {
        let m = p[j].reste > this.grandTotal ? this.grandTotal : p[j].reste;
        m = parseFloat(m);
        b.push({
          paye_id: p[j].id,
          fact_id: this.editedItem.id,
          montant: m,
          create_uid: this.$store.state.me.id,
          write_uid: this.$store.state.me.id,
        });
      }
      if (b.length > 0) {
        let v = {
          list: b,
        };
        this.maj(INSERT_PAYE_DETAIL, v);
      }
    },
    async modifier() {
      if (await this.verif_modif("m")) {
        this.edit = true;
        this.$emit("edition", true);
        this.kdoc++;
      }
    },
    async cancel() {
      let ok = true;
      if (this.cmpt > 2) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en-cours! Etês-vous sûr de vouloir annuler?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) {
        //goto mouvments

        if (this.$route.params.mvm) {
          await this.update_modif();
          this.$router.push({
            name: this.$route.params.name,
            params: {
              mvm: this.$route.params.mvm,
            },
          });
        } else {
          if (this.editedItem.id < 0) {
            this.$emit("cancel", this.editedItem);
            this.editedItem.id = null;
          }
          this.edit = false;
          this.$emit("edition", false);
          await this.update_modif();
          this.$store.dispatch("Changed", true);
          this.kdoc++;
        }
      }
    },
    async deleteFact() {
      if (await this.verif_modif("d")) {
        //verif last fact

        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer cette facture?"
          )
        ) {
          let v = {
            id: this.editedItem.id,
          };
          await this.maj(DELETE_FACT, v, true);
          this.editedItem = { id: null };
          this.$store.dispatch("Editing", false);
        } else this.update_modif();
      }
    },
    async printbl() {
      if (this.fact_type == 1 || this.fact_type == 4)
        reception(
          this.$store,
          this.editedItem.factmvms,
          this.editedItem.factdetails,
          this.editedItem
        );
      else {
        let price =
          this.$store.state.auth.includes("01027") || this.$store.state.isadmin;
        livraison(
          this.$store,
          this.editedItem.factmvms,
          this.fact_detail,
          this.fact,
          price
        );
      }
    },
    async verif_modif(e) {
      this.alert = false;
      let ok = false;
      let v = {
        table: "factures",
        statut_id: "13",
        id: this.editedItem.id,
      };
      this.progress = true;
      let r = await this.maj(UPDATE_MODIF, v);
      this.progress = false;
      if (r) {
        let d = JSON.parse(r.UpdateModif);
        if (d.modif == 1) {
          this.alert = true;
          this.message =
            "Document ouvert en modification par " +
            d.name +
            " depuis le " +
            datefr(d.modif_date, true);
          this.type_alert = "warning";
        } else if (d.modif == 2 && e == "m") {
          this.alert = true;
          this.message = "Document Validé!! Actualiser vos données ";
          this.type_alert = "error";
        } else if (d.modif == -1) {
          this.alert = true;
          this.message = "Document introuvable ";
          this.type_alert = "error";
        } else {
          this.$store.dispatch("Document", v);
          this.$store.dispatch("Editing", true);
          ok = true;
        }
      }
      return ok;
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.editedItem.id > 0) {
        let v = {
          fact: {
            id: this.editedItem.id,
            modif_by: null,
            modif_date: null,
          },
        };

        await this.maj(UPDATE_FACT, v);
      }
    },
    printfact() {
      let doctype = [];
      doctype.push({
        lib: {
          content: this.title,
          styles: { fontStyle: "bold", fontSize: 20, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      doctype.push({
        lib: {
          content: "N° :",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: this.editedItem.nofact,
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Date : ",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: datefr(this.editedItem.fact_date),
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Réference:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.fact_ref
            ? this.editedItem.fact_ref
            : this.editedItem.cmd_ref
            ? this.editedItem.cmd_ref
            : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Mode de Réglement:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.mode ? this.mode : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });

      doctype.push({
        lib: {
          content: "Commandes Ref.:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.cmds_ref
            ? this.editedItem.cmds_ref
            : this.editedItem.cmds_no
            ? this.editedItem.cmds_no
            : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      let tier = [];
      tier.push({
        lib: {
          content: this.fact_type == 1 ? "Béneficiaire" : "Doit :",
          styles: { fontStyle: "normal", fontSize: 12, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content:
            (this.editedItem.forme_juridique
              ? this.editedItem.forme_juridique
              : "") +
            " " +
            this.editedItem.tier_name,
          styles: { fontStyle: "bold", fontSize: 12, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content: this.editedItem.adresse ? this.editedItem.adresse : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content: "Tél :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.tel ? this.editedItem.tel : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      tier.push({
        lib: {
          content: "Email :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.email ? this.editedItem.email : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      tier.push({
        lib: {
          content: "RC  :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.RC ? this.editedItem.RC : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      tier.push({
        lib: {
          content: "NIF  :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.NIF ? this.editedItem.NIF : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      tier.push({
        lib: {
          content: "ART  :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.ART ? this.editedItem.ART : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      tier.push({
        lib: {
          content: "NIS  :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.NIS ? this.editedItem.NIS : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      let foot = [];
      foot.push({
        libelle: this.fact_type == 1 ? "Total" : "Montant HT",
        value: this.numberWithSpace(this.subTotal),
      });
      if (this.fact_type != 1)
        this.taxTotal.forEach((element) => {
          foot.push({
            libelle: "TVA " + element.taux + " %",
            value: this.numberWithSpace(this.decimalDigits(element.tva)),
          });
        });

      if (this.taxTotal.length > 1 && this.fact_type != 1) {
        foot.push({
          libelle: "Total TVA",
          value: this.numberWithSpace(this.decimalDigits(this.sumtaxTotal())),
        });
      }
      if (
        this.subTotal > 0 &&
        this.sumtaxTotal() == 0 &&
        this.editedItem.currency_id == 114 &&
        this.fact_type != 1
      ) {
        foot.push({
          libelle: "TVA ",
          value: "EXONORE",
        });
      }

      if (this.timbre > 0 && this.fact_type != 1) {
        foot.push({
          libelle: "Droit de timbre (1%)",
          value: this.numberWithSpace(this.timbre),
        });
      }
      if (this.mont_exe_taxs > 0 && this.fact_type != 1) {
        foot.push({
          libelle: "Exonoration de la TVA",
          value: {
            content: "-" + this.numberWithSpace(this.mont_exe_taxs),
            styles: { fontStyle: "normal" },
          },
        });
      }
      if (this.fact_type != 1)
        foot.push({
          libelle: "Montant TTC",
          value: {
            content: this.numberWithSpace(this.grandTotal),
            styles: { fontStyle: "bold" },
          },
        });
      if (this.garantie > 0 && this.fact_type != 1)
        foot.push({
          libelle: "Retenue de Garantie " + this.editedItem.garantie + " %",
          value: "-" + this.numberWithSpace(this.garantie),
        });

      if (this.avc_cmds > 0 && this.fact_type != 1)
        foot.push({
          libelle: "Rembourssement Avance Forfaitaire",
          value: "-" + this.numberWithSpace(this.avc_cmds),
        });
      if (this.ttc != this.grandTotal && this.fact_type != 1)
        foot.push({
          libelle: "Montant Net",
          value: {
            content: this.numberWithSpace(this.grandTotal),
            styles: { fontStyle: "bold", fontSize: 10 },
          },
        });
      if (this.mont_exe_taxs > 0 && this.fact_type != 1) {
        foot.push({
          libelle: "TVA 19% (Exonoré)",
          value: this.numberWithSpace(this.mont_exe_taxs),
        });
      }
      let detailheaders = [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          align: "left",
          width: 20,
        },
        {
          text: "Designation",
          value: "label",
          slot: "href99",
          column: "article_id",
          selected: true,
          align: "left",
          width: "auto",
        },
        {
          text: "PU HT",
          value: "pu",
          slot: "cur",
          selected: true,
          width: 30,
        },
        {
          text: "Quantité",
          value: "qte",
          slot: "cur",
          align: "end",
          selected: true,
          width: 20,
        },
        {
          text: "Montant HT",
          value: "ht",
          slot: "cur",
          align: "end",
          selected: true,
          width: 30,
        },
      ];
      let lettre = [];
      lettre.push({
        libelle: {
          content:
            "Arrêtée la présente " +
            this.title.toLowerCase() +
            " à la somme de :",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
      });
      lettre.push({
        libelle: {
          content: this.$lettre.NumberToLetter(this.grandTotal),
          styles: { halign: "left", fontStyle: "normal", fontSize: 10 },
        },
      });

      doc_template(
        this.$store,
        detailheaders,
        this.fact_detail,
        doctype,
        tier,
        foot,
        lettre,
        this.editedItem.note,
        this.title,
        this.fact_type == 1 ? null : this.editedItem.compte,
        this.fact_type == 1 ? false : true,
        "",
        null,
        "p",
        "",
        false,
        this.editedItem.statut_id == 14 &&
          (this.fact_type == 2 || this.fact_type == 3) &&
          this.editedItem.ttc_paye >= this.editedItem.mont_ttc
          ? "Reglée"
          : null
      );

      // facture(
      //   this.$store,
      //   this.fact_detail,
      //   this.editedItem,
      //   this.mode,
      //   this.$lettre,
      //   this.editedItem.factmvms,
      //   this.subTotal,
      //   this.taxTotal,
      //   this.timbre,
      //   this.ttc,
      //   this.garantie,
      //   this.avc_cmds,
      //   this.grandTotal,
      //   this.mont_exe_taxs
      // );
      this.loading = false;
    },
    add_exp() {
      this.showexp = false;
      this.isMvmLettClosed = false;
      this.fkey++;
    },
    add_fact() {
      this.showfact = false;
      this.ffkey++;
      this.isFactLettClosed = false;
    },
    async del_exp(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer cet element?"
        )
      ) {
        let v = {
          id: item.id,
          fact_id: this.editedItem.id,
        };
        await this.maj(DELETE_FACT_MVM, v);
        this.editedItem.factmvms.splice(item.index, 1);
        this.$store.dispatch("Changed", true);
      }
    },
    async del_fact(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer cet element?"
        )
      ) {
        let v = {
          id: item.id,
          fact_id: this.editedItem.id,
        };
        await this.maj(DELETE_FACT_MVM, v);
        this.editedItem.factmvms.splice(item.index, 1);
        this.$store.dispatch("Changed", true);
      }
    },
    add_paye() {
      this.editedItem.facture = "1";
      this.showpaye = false;
      if (this.fact_type == 1)
        this.$router.push({
          name: "paiements",
          params: { document: this.editedItem, name: "facturations_f" },
        });
      if (this.fact_type == 2)
        this.$router.push({
          name: "encaissements",
          params: { document: this.editedItem, name: "facturations_fa" },
        });
      if (this.fact_type == 3)
        this.$router.push({
          name: "encaissements",
          params: { document: this.editedItem, name: "facturations_c" },
        });
      if (this.fact_type == 4)
        this.$router.push({
          name: "paiements",
          params: { document: this.editedItem, name: "facturations_ca" },
        });
    },

    tierChange(item, l) {
      let tier;
      let list = []; // this.tiers_list;
      if (this.editedItem.id < 0) this.mvms_select = [];
      if (l) list = l;
      if (item) this.editedItem.tier_id = item;
      if (this.editedItem.tier_id) {
        this.mvms = this.mvms_list.filter(
          (elm) => elm.tier_id == this.editedItem.tier_id
        );
        if (
          this.mvms.length == 1 &&
          this.editedItem.id < 0 &&
          !this.fact.tier_id
        ) {
          this.mvms_select.push(this.mvms[0].id);
        }
      }
      let i = list.findIndex((elm) => elm.id == this.editedItem.tier_id);
      this.modes_list = [];
      if (i >= 0) {
        tier = list[i];

        if (this.editedItem.id < 0) {
          this.auxiliaires = list[i].auxiliaires;
          if (this.auxiliaires.length == 1) {
            this.editedItem.aux_id = this.auxiliaires[0].aux_id;
          } else {
            let j = this.auxiliaires.findIndex((elm) => elm.aux_default == 1);
            if (j >= 0) this.editedItem.aux_id = this.auxiliaires[j].aux_id;
          }
          this.editedItem.modepaiement = tier.modepaiement;
          this.editedItem.delai_paye = tier.delai_paye;
        }
        this.editedItem.code_tier =
          this.fact_type == 3 || this.fact_type == 4
            ? tier.code_client
            : tier.code_fournisseur;
        this.editedItem.adresse = tier.adresse;
        this.editedItem.RC = tier.RC;
        this.editedItem.NIF = tier.NIF;
        this.editedItem.ART = tier.ART;
        this.editedItem.code_cmpt = tier.code_cmpt;
        this.editedItem.country_id = tier.country_id;
      }

      this.modes_list = this.modes.filter(
        (elm) => elm.type == (this.editedItem.country_id == 13 ? 0 : 1)
      );
      this.cmpt++;
      //this.$refs.form.resetValidation();
    },

    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },

    ClosePayeForm() {
      this.showpaye = false;
    },
    CloseExpForm() {
      this.showexp = false;
    },
    CloseFactForm() {
      this.showfact = false;
    },
    nbjour() {
      if (this.editedItem.fact_date && this.duedate) {
        var date2 = new Date(this.duedate);
        var date1 = new Date(this.editedItem.fact_date);
        var diff = date2.getTime() - date1.getTime();
        this.editedItem.delai_paye = Math.floor(diff / (24 * 3600 * 1000));
        this.cmpt++;
      } else this.editedItem.delai_paye = null;
    },
    gotier() {
      if (this.fact_type == 3 || this.fact_type == 4)
        this.$router.push({
          name: "clients",
          params: {
            facture: this.fact,
            tier_id: this.editedItem.tier_id,
            code_tier: this.editedItem.code_tier,
            tier_name: this.editedItem.tier_name,
            name: this.fact_type == 3 ? "facturations_c" : "facturations_ca",
          },
        });
      if (this.fact_type == 1 || this.fact_type == 2)
        this.$router.push({
          name: "fournisseurs",
          params: {
            facture: this.fact,
            tier_id: this.editedItem.tier_id,
            code_tier: this.editedItem.code_tier,
            tier_name: this.editedItem.tier_name,
            name: this.mvm_type == 1 ? "facturations_f" : "facturations_fa",
          },
        });
    },
    addDays() {
      if (this.editedItem.delai_paye) {
        let d = parseInt(this.editedItem.delai_paye);
        var result = new Date(this.editedItem.fact_date);
        result.setDate(result.getDate() + d);
        this.duedate = result.toISOString().substr(0, 10);
        this.kdd++;
      } else this.duedate = null;
      this.cmpt++;
    },
    datefr(date, time = false, long = true) {
      let t = "";
      let option = {
        year: "numeric",
        month: long ? "long" : "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    DetailSelect(item) {
      this.factdetail = item;
    },
    async OpenDetailForm(item) {
      if (item.col == "code") {
        if (this.fact_type == 3 || this.fact_type == 4)
          await this.$router
            .push({
              name: "product_tosell",
              params: {
                facture: this.fact,
                item: item,
                name:
                  this.fact_type == 3 ? "facturations_c" : "facturations_ca",
              },
            })
            .catch(() => {});
        if (this.fact_type == 1 || this.fact_type == 2)
          await this.$router
            .push({
              name: "product_tobuy",
              params: {
                facture: this.fact,
                item: item,
                name:
                  this.fact_type == 1 ? "facturations_f" : "facturations_fa",
              },
            })
            .catch(() => {});
      } else if (await this.verif_modif("m")) {
        this.factdetail = item;

        this.factdetail.fact_id = this.editedItem.id;
        this.factdetail.fact_type = this.fact_type;
        this.factdetail.tier_id = this.editedItem.tier_id;
        this.dkey++;
        this.isDetailClosed = false;
      }
    },
    closeDetailForm() {
      this.update_modif();
      this.isDetailClosed = true;
    },
    get_desc(list, colis_unit, unit) {
      let desc = "";
      if (list.length > 0) {
        var unique = list.map((elm) => elm.article_id).filter(onlyUnique);
        if (unique.length > 1) {
          unique.forEach((elm2) => {
            let l = list.findIndex((elm) => elm.article_id == elm2);
            if (l.length > 0) {
              desc = desc + " " + l[0].label + "\n";
              list
                .filter((elm) => elm.article_id == l[0].link_id)
                .forEach((element) => {
                  desc =
                    desc +
                    element.nb.toString() +
                    " " +
                    colis_unit +
                    (element.nb > 1 ? "s" : "") +
                    " / " +
                    element.qte +
                    " " +
                    unit +
                    " " +
                    "\n";
                });
            }
          });
        } else
          list.forEach((element) => {
            desc =
              desc +
              element.nb.toString() +
              " " +
              colis_unit +
              (element.nb > 1 ? "s" : "") +
              " / " +
              element.qte +
              " " +
              unit +
              " " +
              "\n";
          });
        if (list.length > 1 && unique.length == 1)
          desc =
            desc +
            "Soit " +
            list.reduce((a, b) => a + (b["nb"] || 0), 0) +
            " " +
            colis_unit +
            "s pour un total de " +
            list.reduce(
              (a, b) => a + (parseFloat(b["qte"]) * parseInt(b["nb"]) || 0),
              0
            ) +
            " " +
            unit;
      }
      return desc;
    },
  },

  computed: {
    monobl() {
      return monobl;
    },
    tot_mvms() {
      return this.editedItem.factmvms
        ? this.editedItem.factmvms.reduce((a, b) => a + (b["mont_ttc"] || 0), 0)
        : 0;
    },
    variableadd() {
      let w = {
        TypeScope: this.fact_type == 1 || this.fact_type == 2 ? 1 : 2,
        statut_id: [2, 3],
        Kind: 0,
      };
      return w;
    },
    tierscursor() {
      return TIERS_CURSOR;
    },
    auth() {
      let a =
        this.fact_type == 1
          ? "01014"
          : this.fact_type == 2
          ? "01015"
          : this.fact_type == 3
          ? "01012"
          : "01013";
      return a;
    },

    paye_list: function () {
      return this.editedItem.id > 0 ? this.editedItem.paiements : [];
    },
    existe_remise() {
      let i = this.detail_list.findIndex((elm) => elm.remise > 0);
      return i >= 0;
    },
    tier_title() {
      let t;
      if (this.fact_type == 1 || this.fact_type == 2) t = "Fournisseur";
      if (this.fact_type == 3 || this.fact_type == 4) t = "Client";
      return t;
    },
    title() {
      let m;
      if (this.fact_type == 1 || this.fact_type == 3) {
        m = "Facture ";
      }
      if (this.fact_type == 2 || this.fact_type == 4) {
        m = "Facture Avoir ";
      }
      return m;
    },
    formTitle() {
      return this.editedItem.id < 0
        ? "Nouvelle " + this.title
        : this.title +
            (this.editedItem.nofact ? " N° " + this.editedItem.nofact : "");
    },
    logo() {
      return myLogo;
    },
    myname() {
      return myName;
    },

    mode() {
      let i = this.modes.findIndex(
        (elm) => elm.id == this.editedItem.modepaiement
      );
      let modepaiement = "";
      if (i >= 0) modepaiement = this.modes[i].mode;
      return modepaiement;
    },

    tier_label() {
      let t;
      if (this.fact_type == 3 || this.fact_type == 4) t = "Client";
      if (this.fact_type == 1 || this.fact_type == 2) t = "Fournisseur";
      return t;
    },

    myrc() {
      return myRC;
    },
    myif() {
      return myIF;
    },
    myart() {
      return myART;
    },

    operations: function () {
      return this.editedItem.id > 0 ? this.editedItem.operations : [];
    },

    detail_list: function () {
      return this.editedItem.factdetails
        ? this.editedItem.factdetails.filter((elm) => elm.qte > 0)
        : [];
    },
    detail_extra: function () {
      return this.editedItem.factdetailsextra
        ? this.editedItem.factdetailsextra
        : [];
    },

    fact_detail() {
      let items = [];
      let k;
      if (this.detail_list && this.editedItem.msq_origin == 0)
        this.detail_list.forEach((element) => {
          let colis_json = [];
          if (element.colis_json) colis_json = JSON.parse(element.colis_json);
          //search retours
          let colis_json_retours = [];
          let i = this.editedItem.factmvms.findIndex(
            (elm) => elm.mvm_id == element.mvm_id
          );
          if (i >= 0) {
            this.editedItem.factmvms[i].retours
              .filter((elm) => elm.decision_ret == 3 && elm.statut_id == 11)
              .forEach((retour) => {
                let j = retour.mvmdetails.findIndex(
                  (elm) => elm.mvm_det_id == element.id
                );

                if (j >= 0) {
                  let cj = JSON.parse(retour.mvmdetails[j].colis_json);

                  if (cj)
                    cj.forEach((element) => {
                      let p = colis_json_retours.findIndex(
                        (elm) =>
                          elm.article_id == element.article_id &&
                          elm.qte == element.qte
                      );
                      if (p >= 0)
                        colis_json_retours[p].nb =
                          colis_json_retours[p].nb + element.nb;
                      else {
                        colis_json_retours.push(element);
                      }
                    });
                }
              });
          }

          colis_json.forEach((cj) => {
            let p = colis_json_retours.findIndex(
              (elm) => elm.article_id == cj.article_id && elm.qte == cj.qte
            );
            if (p >= 0) cj.nb = cj.nb - colis_json_retours[p].nb;
          });
          //end retours
          k = items.findIndex(
            (elm) =>
              elm.article_id == element.article_id &&
              elm.pu == element.pu &&
              elm.remise == element.remise
          );
          if (k >= 0) {
            items[k].qte = items[k].qte + element.qte;
            items[k].ht =
              (element.pu - (element.pu * element.remise) / 100) * items[k].qte;
            items[k].ht = Math.round(items[k].ht * 100) / 100;

            colis_json.forEach((cj) => {
              let p = items[k].colis_json.findIndex(
                (elm) => elm.article_id == cj.article_id && elm.qte == cj.qte
              );
              if (p >= 0)
                items[k].colis_json[p].nb = items[k].colis_json[p].nb + cj.nb;
              else {
                items[k].colis_json.push(cj);
              }
            });
            items[k].description = this.get_desc(
              items[k].colis_json.filter((elm) => elm.nb > 0),
              element.colis_unit,
              element.unit
            );
          } else {
            let desc = this.get_desc(
              colis_json.filter((elm) => elm.nb > 0),
              element.colis_unit,
              element.unit
            );
            items.push({
              id: element.id,
              article_id: element.article_id,
              produit_id: element.produit_id,
              code: element.code,
              label: element.product_name,
              pu: element.pu,
              remise: element.remise,
              qte: element.qte,
              mvm_id: element.mvm_id,
              ht:
                (element.pu - (element.pu * element.remise) / 100) *
                element.qte,
              tva_tx: element.tva_tx,
              comment: element.comment,
              description: desc,
              nocmd: element.nocmd,
              cmd_ref: element.cmd_ref,
              colis_json: colis_json.filter((elm) => elm.nb > 0),
            });
          }
        });

      if (this.detail_extra)
        this.detail_extra.forEach((element) => {
          items.push({
            id: element.id,
            code: element.code,
            label: element.label,
            produit_id: element.produit_id,
            nobon: "",
            mont_ttc: element.pu,
            pu: element.pu,
            qte: element.qte,
            remise: 0,
            ht: parseFloat((element.pu * element.qte).toFixed(2)),
            tva_tx: element.tva_tx,
            comment: element.comment,
          });
        });

      return items;
    },

    subTotal: function () {
      var total = 0;

      total = this.fact_detail.reduce(function (accumulator, item) {
        return (
          accumulator +
          parseFloat(
            ((item.pu - (item.pu * item.remise) / 100) * item.qte).toFixed(2)
          )
        );
      }, 0);
      total = total.toFixed(2);
      return total;
    },
    remise: function () {
      var total = this.fact_detail
        ? this.fact_detail.reduce(function (accumulator, item) {
            return (
              accumulator +
              parseFloat(
                (((item.pu * item.remise) / 100) * item.qte).toFixed(2)
              )
            );
          }, 0)
        : 0;
      total = parseFloat(total).toFixed(2);
      return total;
    },
    taxTotal: function () {
      let taxs = [];

      if (this.editedItem.exo_tva == 0)
        this.fact_detail.forEach((element) => {
          if (
            element.tva_tx &&
            (element.exe_tva == "0" || element.exe_tva == null)
          ) {
            let ht = (
              (element.pu - (element.pu * element.remise) / 100) *
              element.qte
            ).toFixed(2);

            let i = taxs.findIndex((elm) => elm.taux == element.tva_tx);
            if (i >= 0) {
              taxs[i].ht = parseFloat(taxs[i].ht) + parseFloat(ht);
              taxs[i].tva =
                parseFloat(taxs[i].tva) +
                parseFloat(ht * (element.tva_tx / 100));
            } else
              taxs.push({
                ht: parseFloat(ht),
                taux: element.tva_tx,
                tva: ht * (element.tva_tx / 100),
                exe_tva: element.exe_tva,
              });
          }
        });

      return taxs;
    },

    mont_exe_taxs: function () {
      let taxs = 0;

      let taux_avc = 0;
      let reste_avc = 0;
      if (this.payes_list.length > 0) {
        taux_avc = this.payes_list[0].taux_avance;
        reste_avc = this.payes_list[0].reste;
      }

      this.fact_detail.forEach((element) => {
        if (element.exe_tva == "1") {
          let montant_avc = 0;

          montant_avc = (element.pu * element.qte * taux_avc) / 100;
          montant_avc = parseFloat(montant_avc.toFixed(2));
          if (montant_avc > reste_avc) montant_avc = reste_avc;
          let v =
            (((element.pu - (element.pu * element.remise) / 100) * element.qte -
              montant_avc) *
              element.tva_tx) /
            100;
          v = parseFloat(v.toFixed(2));
          taxs = taxs + v;
        }
      });
      taxs = taxs.toFixed(2);

      return taxs;
    },
    timbre: function () {
      var timbre = 0;
      if (this.editedItem.modepaiement == "4") {
        let taxs = 0;
        this.taxTotal.forEach((element) => {
          taxs =
            taxs + parseFloat((element.ht * (element.taux / 100)).toFixed(2));
        });

        timbre = Math.ceil((this.subTotal + taxs) / 100);
        if (timbre < 5) timbre = 5;
        if (timbre > 2500) timbre = 2500;
      }
      timbre = timbre.toFixed(2);
      return timbre;
    },
    ttc: function () {
      let taxs = 0;
      this.taxTotal.forEach((element) => {
        taxs =
          taxs + parseFloat((element.ht * (element.taux / 100)).toFixed(2));
      });
      var total =
        parseFloat(this.subTotal) + (taxs ? taxs : 0) + parseFloat(this.timbre);
      total = total.toFixed(2);
      return total;
    },

    garantie: function () {
      let grt = 0;
      if (this.editedItem.garantie > 0 && this.fact_type == 3) {
        grt = parseFloat(
          ((this.ttc * this.editedItem.garantie) / 100).toFixed(2)
        );
      }
      return grt;
    },
    avc_cmds: function () {
      let avc = 0;
      if (this.payes_list.length > 0 && this.fact_type == 3) {
        avc = parseFloat(
          ((this.subTotal * this.payes_list[0].taux_avance) / 100).toFixed(2)
        );
        if (avc > this.payes_list[0].reste) avc = this.payes_list[0].reste;
      }
      return avc;
    },
    grandTotal: function () {
      var total = this.ttc - this.garantie - this.avc_cmds;
      total = total.toFixed(2);

      return total;
    },
  },
};
</script>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.text-green input {
  color: green !important;
}
.text-input-blue .v-text-field__slot input {
  color: #00f !important;
}
</style>
